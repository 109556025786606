<template>
    <div>
       
        <Head :title="'信息采集'" :out="`PreserveTheSend?active=${$route.query.idx}`" />
        <div class="infobtn">
            <van-button type="primary" size="large" v-for="item in list" :key="item.id" @click="upImgItem(item)">
                {{item.name}}
            <van-tag v-if="item.value.length!=0" type="danger" size="medium">已上传</van-tag>

            </van-button>
        </div>
        <div class="submitT">

            <van-button @click="OnSubmit()">提交审核</van-button>
        </div>
        <van-popup
            v-model="show"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{height: '100%',}"
            >
            <uploadingImg ref="uploadRef" @imgCloes="imgCloes" :isTrue="true">
                <div class="submit">
                    <van-button  @click="imgCloes">取消</van-button>
                    <van-button type="primary" @click="obSubmit">提交</van-button>
                </div>
            </uploadingImg>
        </van-popup>
        <van-dialog v-model:show="IsShow" title="司机信息" show-cancel-button @confirm="confirmHan" :before-close="onBeforeClose">
            <div class="box">
                <van-field v-model="realName" label-width="40%" label="联系人：" placeholder="填写终点位置"  />
                <van-field v-model="phone" label-width="40%" label="手机号：" placeholder="填写终点位置"  />
            </div>
        </van-dialog>
    </div>
</template>

<script>
import Head from '@/components/Head'
import {DataCollectionFind,CollectionSave,DataCollection} from '@/api/send'
import uploadingImg from '@/components/up/uploadingImg'
import {is_post} from '@/Http/api'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
    export default {
        components: {
            Head,uploadingImg
        },
        data() {
            return {
                list:[],
                show:false,
                IsShow:false,
                listId:'',
                realName:"",
                phone:"",
            }
        },
        mounted () {
            this.lodData();
        },
        methods: {
            confirmHan(){
                this.tijiao(this.phone ,this.realName)
            },
            onBeforeClose(action,done){
                if (action === "confirm") {//点击确定按钮
                    return done(false);//阻止关闭
                } else {
                    return done()//允许关闭
                }
            },
            async obSubmit(){
                try{
                    let obj = {
                        id:this.$route.query.id,
                        type:this.listId,
                        data:this.$refs.uploadRef.WXimg
                    }
                    const {data} = await DataCollection(obj).then(res=>res)
                    this.$toast.success(data.msg)
                    this.imgCloes()
                }
                catch{}
                
            },
            async OnSubmit(){
                try{
                    let data = encipherMent( JSON.stringify({id:window.localStorage.getItem('userId')}))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post('index.php/index/user/_get',param).then(res=>res)
                    this.IsShow = true
                    this.phone = res.data.phone
                    this.realName = res.data.realName
                    // this.tijiao(res.data.phone ,res.data.realName)
                }
                catch{}
               
            },
            async tijiao(phone,name){
                try{
                    const {data} = await CollectionSave({id:this.$route.query.id,trailer_driver:name,trailer_driver_phone:phone}).then(res=>res)
                    this.$router.push({path:'/PreserveTheSend',query:{active:1}})
                }
                catch{}

            },
            imgCloes(){
                this.show = false
                this.lodData()
            },
            async lodData() {
                const {data} = await DataCollectionFind({id:this.$route.query.id}).then(res=>res)
                console.log(data)
                this.list = data.data
            },
            upImgItem(val){
                this.show = true
                this.listId = val.id
                this.$nextTick(()=>{
                    this.$refs.uploadRef.kong(val)
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.infobtn{
    margin-top: 50px;
    text-align: center;
    padding: 10px;
    height: 80vh;
    overflow-y: scroll;
    .van-button{
        margin: 10px 0;
    }
}
.submitT{
    margin-top: 20px;
    text-align: center;
    .van-button{
     background: #409EFF;   
     color: #ffff;
    }
}
.box{
    padding: 10px;
    p{
        text-align: center;
        font-weight: bold;
    }
}
</style>
