<template>
    <div>
        <div class="header" v-if="isTrue">
            <span @click="gouot"></span>
            <span>照片采集</span>
            <span @click="islogout">退出</span>
        </div>
        <div :class="[isTrue?'imgcontent':'']">
            <div v-if="!isWechat">
                <van-uploader  v-model="imageFile" :after-read="uploadershang" :before-read="beforeRead" multiple  :before-delete="deleteimg2"></van-uploader>
                    <!-- <van-field name="uploader" label=" ">
                        <template #input>
                            <div v-for="(item,index) in WXimg" :key="index">
                                <van-image width="80"  height="80"  :src="item" />
                            </div>
                        </template>
                        
                    </van-field> -->
                </div>

                <div class="upimg" v-else>
                    <!-- <p>图片上传</p> -->
                    <p>
                        <span class="imgbox" v-for="(item,index) in WXimg" :key="index">
                            <van-image class="vanImage" width="80"  height="80"  :src="item" @click="bigImg(item)" >
                                <van-icon class="iconX" name="close" @click="delImgHan('arrPhoto',index)" />
                            </van-image>
                        </span>
                        <span class="uploaderImg" @click="WeChooseImage(9)"> 
                            <van-icon class="iconz" name="plus" />
                        </span>
                    </p>
                </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import {Toast} from 'vant'
import {DataCollection} from '@/api/send'
    export default {
        props:['isTrue','objectionValue'],
        data() {
            return {
                wximagelocalids:[],//判断上传张数
                toast:{},
                WXimg:[],
                imageFile:[],
                listId:'',
               
            }
        },
        watch: {
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            },
            objectionValue: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    
                    let arr = []
                    newValue.forEach(i=>{
                        let obj = {
                            url:i
                        }
                        arr.push(obj)
                    })
                    this.imageFile = arr
                    if(this.isWechat){
                        this.wcConfig();
                    }
                }
            }
        },
     
        methods: {
           
            kong(val) {
                console.log(val)
                this.listId = val.id
                this.WXimg = val.value
                let arr = []
                val.value.forEach(i=>{
                    let obj = {
                        url:i
                    }
                    arr.push(obj)
                })
                this.imageFile = arr
                
                if(this.isWechat){
                    this.wcConfig();
                }
            },
            gouot(){
                this.$emit('imgCloes')
            },
            async obSubmit(){
            
                try{
                    let obj = {
                    id:this.$route.query.id,
                    type:this.listId,
                    data:this.WXimg
                    }
                    const {data} = await DataCollection(obj).then(res=>res)
                    this.$toast.fail(data.msg)
                    this.$emit('imgCloes')
                }catch{}
               

            },
            //上传格式校验
            beforeRead(file){
                if(file instanceof Array){
                    for(let i=0;i<file.length;i++){
                        if ((file[i].type !== 'image/jpeg') && (file[i].type !== 'image/png')) {
                            Toast('请上传 jpg/png 格式图片');
                            return false;
                        }
                        return true;
                    }
                
                }else{
                    if((file.type !== 'image/jpeg') && (file.type !== 'image/png')) {
                        Toast('请上传 jpg/png 格式图片');
                        return false;
                    }
                    return true;
                }
           },
           //删除
           deleteimg2(file,datail){
              this.imageFile.forEach((item,index)=>{
                   if(index == datail.index){   // 判断删除的下标是否正确
                        this.imageFile.splice(index,1) // 使用遍历找到的index去数组里面删除
                    }
              })
              this.WXimg.forEach((item,index)=>{
                if(index == datail.index){   // 判断删除的下标console.log(this.WXimg)
                    this.WXimg.splice(index,1) // 使用遍历找到的index去数组里面删除
                }
            })
           },
             // 上传照片
             uploadershang(file){
              
                file.status = "uploading";
                file.message = "上传中...";
                if(file instanceof Array){
                    file.forEach(item=>{
                        this.upImages(item)
                    })
                }else{
                    this.upImages(file)
                }
                
           },
           //上传
           upImages(file){
                file.status = "uploading";
                file.message = "上传中...";
                let params = new FormData(); //创建form对象
                params.append("file", file.file);
            this.$http.post("/index.php/index/upload/index", params)
            .then((res) => {
            if (res.data.code == 0) {
                    file.status = "done";
                    this.WXimg.push(res.data.data)
                    this.$emit('imgindex', this.WXimg)
                } else if(res.data.code == -1){
                    deleteCookie('token')
                    this.$toast.fail(res.data.msg)
                    this.$router.push('/')
                }else{
                    file.status = "failed";
                   file.message = "上传失败";
                    this.$toast.fail(res.data.msg)
                }
            }).catch(()=>{
                this.imageFile = []
                file.status = "failed";
                file.message = "上传失败";
                this.$toast.fail('网络开小差了，请稍后重试！')
            })
           }
        },
    }
</script>

<style lang="scss" scoped>
.imgcontent{
    margin-top: 50px;
    padding: 10px;
    .submit{
        text-align: center;
    }
}
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    span {
        display: inline-block;
    }
    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}
</style>
